import theme from "@elegantstack/flow-ui-theme/src/theme/index"
//Using tailwind preset colors
import colorPreset from "@elegantstack/flow-ui-theme/src/theme/color-preset"
export default {
  ...theme,
  colors: {
    ...theme.colors,
    alphaLighter: colorPreset.blue[100],
    alphaLight: colorPreset.blue[300],
    alpha: colorPreset.blue[600],
    alphaDark: colorPreset.blue[800],
    alphaDarker: colorPreset.blue[900],
  },
}
